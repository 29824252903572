var $class="se2--line-slide",$name="lineSlide",$path="app/partials/lineSlide/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';
import replaceSpecialTags from 'app/utilities/replaceSpecialTags';
import toBoolean from 'app/utilities/toBoolean';

import { css, directions } from 'configs';

import forEach from 'lodash/forEach';
import gsap from 'gsap';

export default shell.registerComponent($this, ({
  addEventListener, mount, settings,
}) => {
  mount((element) => {
    const triggers = [];
    const hasAnimation = toBoolean(element.dataset.hasAnimation);
    const isCutRefresh = toBoolean(element.dataset.isCutRefresh);
    const isPaused = toBoolean(element.dataset.isPaused);
    const isScrub = toBoolean(element.dataset.isScrub);
    let lines = [];
    let timeline = null;
    let initialContent = null;
    let isCut = false;
    let isOpened = false;

    const play = () => {
      isOpened = true;
      timeline.play();
    };

    // TODO: the whole RTL functionality should be tested later on when components with green text
    // will be created so it should be decided is this function is needed later
    const upload = () => {
      if (!hasAnimation || !lines.length) return;

      timeline = gsap.timeline({ paused: isPaused });
      timeline.set(element, { opacity: 1 });

      if (settings.direction !== directions.rtl) {
        timeline.fromTo(
          lines,
          { y: 100 },
          {
            y: 0,
            duration: 0.8,
            stagger: {
              axis: 'y',
              each: 0.05,
              grid: 'auto',
            },
            ease: 'power2.out',
            scrollTrigger: {
              trigger: element,
              start: '100px bottom',
              end: 'center center',
              scrub: isScrub,
            },
          },
          0.1,
        );

        timeline.fromTo(
          element,
          { y: 0, opacity: 1 },
          {
            y: 100,
            opacity: 0,
            duration: 0.5,
            scrollTrigger: {
              trigger: element,
              start: 'center center',
              end: 'bottom top',
              scrub: 1,
            },
          },
        );
      } else {
        if (triggers.length) {
          forEach(triggers, (trigger) => trigger.kill());
          triggers.length = 0;
        }

        const revealTrigger = gsap.timeline({
          scrollTrigger: {
            trigger: element,
            start: 'top bottom',
            once: true,
            end: 'bottom bottom',
            scrub: isScrub,
          },
        });

        revealTrigger.fromTo(
          lines,
          { y: 100 },
          {
            y: 0,
            duration: 0.8,
            stagger: {
              axis: 'y',
              each: 0.05,
              grid: 'auto',
            },
            ease: 'power2.out',
          },
          0.1,
        );

        triggers.push(revealTrigger);
      }

      if (isOpened || !isPaused) play();
    };

    const cutLines = () => {
      if (isCut && !isCutRefresh) return lines;

      if (settings.direction === directions.rtl) {
        initialContent = initialContent.replace(/<(?!\/?!\/?)[^>]+>/g, '');
      }

      element.innerHTML = replaceSpecialTags(initialContent, 'span', ' ');

      const words = element.querySelectorAll('span');

      let lastOffset = -Infinity;
      const cutLinesArray = [];
      let currentLine = -1;

      for (let i = 0; i < words.length; i++) {
        const word = words[i];

        if (word.offsetTop > lastOffset) {
          lastOffset = word.offsetTop;
          currentLine += 1;
          cutLinesArray[currentLine] = document.createElement('div');
          cutLinesArray[currentLine].classList.add(css.classNames.purposeLine);
        }
        cutLinesArray[currentLine].innerHTML += `${word.outerHTML} `;
      }
      element.innerHTML = '';
      forEach(
        cutLinesArray,
        (line) => {
          const container = document.createElement('div');
          container.classList.add(css.classNames.purposeLineContainer);
          container.appendChild(line);
          element.appendChild(container);
        },
      );
      lines = cutLinesArray;
      isCut = true;
      return cutLinesArray;
    };

    const startLineAnimation = () => {
      initialContent = element.innerText;
      cutLines();
      upload();
    };

    const resizeLineAnimation = () => {
      isCut = false;
      element.innerHTML = initialContent;
      cutLines();
      upload();
    };

    startLineAnimation();
    addEventListener(window, 'resize', resizeLineAnimation);
  });
});
