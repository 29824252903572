var $class="se2--abanner-asset-with-bottom-text-no-link",$name="ABannerAssetWithBottomTextNoLink",$path="app/components/ABannerAssetWithBottomTextNoLink/index.js",$this={$class,$name,$path,};import initializeParallax from 'app/components/BannerImageAndDataLayers/utilities/initializeParallax';

import shell from 'app/modules/shell';

import 'app/partials/lineSlide';
import 'app/partials/image';

import getHeaderHeightReduced from 'app/utilities/getHeaderHeightReduced';

import gsap from 'gsap';

const primaryBlockContainerClassName = 'primary-block-container';
const backgroundClassName = 'fixed-background';
const initialPosition = 'absolute';
const slideDownContainerClassName = 'slide-down-container';
const slideDownButtonClassName = 'slide-down-button';
const slideDownLabelClassName = 'slide-down-label';
const slideDownLineClassName = 'slide-down-line';

export default shell.registerComponent($this, ({
  addEventListener, mount, settings, subscribeToMessage,
}) => {
  let timeline = null;

  const addAnimationOnButtonInit = (element) => {
    const scrollDownContainerElement = element.querySelector(`.${slideDownContainerClassName}`);
    const scrollDownLabelElement = scrollDownContainerElement.querySelector(`.${slideDownLabelClassName}`);
    const scrollDownLineElement = scrollDownContainerElement.querySelector(`.${slideDownLineClassName}`);

    timeline = gsap.timeline({ paused: true });
    timeline.set(scrollDownContainerElement, { opacity: 1 });
    timeline.fromTo(scrollDownLabelElement, { opacity: 0 }, { opacity: 1, duration: 0.5 }, 1);
    timeline.fromTo(scrollDownLineElement, { y: -120 }, { y: 0, duration: 0.8, ease: 'expo.out' }, 1.4);

    timeline.fromTo(scrollDownContainerElement, { opacity: 1 }, {
      opacity: 0,
      duration: 0.5,
      ease: 'expo.out',
      scrollTrigger: {
        trigger: element,
        start: 'top top',
        end: '+=100',
        scrub: 0.5,
      },
    });
  };

  const addScrollAnimationOnButtonClick = (element) => {
    const scrollDownBtnElement = element.querySelector(`.${slideDownButtonClassName}`);
    const primaryBlockContainerElement = element.querySelector(`.${primaryBlockContainerClassName}`);
    addEventListener(scrollDownBtnElement, 'click', () => {
      const { bottom } = primaryBlockContainerElement.getBoundingClientRect();
      gsap.to(window, {
        duration: 0.3,
        scrollTo: {
          y: (bottom + window.scrollY - getHeaderHeightReduced()),
        },
        ease: 'none',
      });
    });
  };

  mount((element) => {
    const backgroundElement = element.querySelector(`.${backgroundClassName}`);

    initializeParallax(element, backgroundElement, initialPosition, settings, subscribeToMessage);
    addAnimationOnButtonInit(element);
    addScrollAnimationOnButtonClick(element);
    timeline && timeline.play();
  });
});
