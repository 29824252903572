var $name="replaceSpecialTags",$path="app/utilities/replaceSpecialTags.js",$this={$name,$path,};import { css } from 'configs';

import forEach from 'lodash/forEach';

const replaceSpecialTags = (
  textContent,
  tagName,
  space,
  className = css.classNames.purposeTextGreenAnim,
) => {
  let innerHtml = '';
  let greenText = false;

  forEach(
    textContent?.trim()
      .replace(/\s*(<\/?!>)\s*/gi, ' $1 ')
      .split(' '),
    (word, i, { length }) => {
      if (/<!>/.test(word)) greenText = true;

      const text = word.replace(/<\/?!>/g, '');

      if (text) {
        innerHtml
          += `<${tagName} class='${greenText ? className : ''}'>${text}${i !== length - 1 ? space : ''}</${tagName}>`;
      }

      if (/<\/!>/.test(word)) greenText = false;
    },
  );
  return innerHtml;
};

export default replaceSpecialTags;
